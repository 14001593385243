<template>
  <Tile :title="title">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="tile-expand-content tile-background-color p-0">
        <b-container fluid v-if="customer.pc || customer.rpc || customer.pc_assigned || customer.used_pcs.length > 0" class="p-0 pt-1">
          <b-row class="ml-2" v-if="customer.pc">
            <b-col>
              <div>Aktuell verwendeter PC:</div>
            </b-col>
          </b-row>
          <b-row v-if="customer.pc" class="mt-2 mx-2">
            <b-col>
              <ComputerRow :computer="customer.pc" @refresh="refresh"/>
            </b-col>
          </b-row>
          <b-row class="ml-2" v-if="customer.rpc">
            <b-col>
              <div>Aktuell verwendeter RPC oder RZPC:</div>
            </b-col>
          </b-row>
          <b-row v-if="customer.rpc" class="mt-2 mx-2">
            <b-col>
              <ComputerRow :computer="customer.rpc" @refresh="refresh"/>
            </b-col>
          </b-row>
          <b-row class="ml-2" v-if="customer.pc_assigned">
            <b-col>
              <div>Zugewiesener PC:</div>
            </b-col>
          </b-row>
          <b-row v-if="customer.pc_assigned" class="mt-2 mx-2">
            <b-col>
              <ComputerRow :computer="customer.pc_assigned" @refresh="refresh"/>
            </b-col>
          </b-row>
          <b-row v-if="customer.used_pcs.length > 0" class="ml-2">
            <b-col>
              <span>Ehemalig benutzte PCs:</span>
              <div v-for="pc in customer.used_pcs" :key="pc.id" class="ml-3">
                <div>{{ pc.hostname }}</div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile";
import ComputerRow from "@/components/lists/computerList/ComputerRow";

export default {
  name: "TileComputer",
  props: ['title', 'customer'],
  components: {
    Tile,
    ComputerRow
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
