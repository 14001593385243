<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">Benutzerinformationen
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
    </h5>
    <TransitionExpand>
      <div v-if="expand">
        <h6 v-for="(value, key) in userData" :key="key">
        <span v-if="key === 'Rollen'">
          {{ key }}:<b-badge v-for="elem in value" :key="elem" class="ml-2">
            {{ elem }}
          </b-badge>
        </span>
          <span v-else>{{ key }}: {{ value }}</span>
        </h6>
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TransitionExpand from "@/components/utility/TransitionExpand";

export default {
  name: "UserInformation",
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    userData() {
      if(this.user) {
        return {
          'Username': this.user.username,
          'Name': this.user.forename + ' ' + this.user.surname,
          'E-Mail': this.user.email,
          'Rollen': this.user.roles,
          'Position': this.user.position,
          'Standort': this.user.site ? this.user.site.displayname : 'Kein Standort vorhanden',
          'Team': (this.user.site && this.user.site.team) ? this.user.site.team.displayname : 'Kein Team vorhanden'
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>