<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">E-Mail Benachrichtigungen
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      <b-icon-info-circle-fill class="ml-2" id="tt-info-mail"/>
      <b-tooltip target="tt-info-mail" triggers="hover">Beinhaltet das Erhalten von E-Mail Benachrichtigungen von Personen, die man selbst vertritt</b-tooltip>
    </h5>
    <TransitionExpand>
      <b-form-group v-if="expand">
        <b-form-checkbox v-model="userSettings.notification_email_news" switch @input="emitUserSetting">
          E-Mail bei neuen News
        </b-form-checkbox>
        <b-form-checkbox v-model="userSettings.notification_email_license_notice" switch @input="emitUserSetting">
          E-Mail bei Anmerkungen der FG_Software
        </b-form-checkbox>
        <b-form-checkbox v-model="userSettings.notification_email_command_inactive" switch @input="emitUserSetting">
          E-Mail bei Veränderungen von Aktionen
        </b-form-checkbox>
      </b-form-group>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/utility/TransitionExpand"

export default {
  name: "MailSettings",
  props: ['userSettings'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false
    }
  },
  methods: {
    emitUserSetting() {
      this.$emit('settings-changed', this.userSettings)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
