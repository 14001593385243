<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">Favorisierte Standorte
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      <b-icon-info-circle-fill class="ml-2" id="tt-info-favorite-sites"/>
      <b-tooltip target="tt-info-favorite-sites" triggers="hover">Auswahl der favorisierten Standorte</b-tooltip>
    </h5>
    <TransitionExpand>
      <b-form-group v-if="expand">
        <b-form-row class="mt-2">
          <b-col cols="6">
            <label>Standorte:</label>
            <b-form-tags id="tags-with-dropdown" v-model="siteSelect.value" no-outer-focus class="mb-2"
                         @input="emitUserSetting">
              <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag variant="primary" :title="tag" :disabled="disabled" @remove="removeTag(tag)">
                      {{ tag }}
                    </b-form-tag>
                  </li>
                </ul>
                <b-dropdown size="sm" variant="outline-primary" block menu-class="w-100">
                  <template #button-content>
                    Standort auswählen
                  </template>
                  <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group label="Standort suchen" label-for="tag-search-input" label-cols-md="auto" class="mb-0"
                                  label-size="sm" :description="searchDesc" :disabled="disabled">
                      <b-form-input v-model="siteSelect.search" id="tag-search-input" type="search" size="sm"
                                    autocomplete="off"/>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider/>
                  <b-dropdown-item-button v-for="option in availableOptions" :key="option.id"
                                          @click="onOptionClick({ option, addTag })">
                    {{ option.displayname }}
                  </b-dropdown-item-button>
                  <b-dropdown-text v-if="availableOptions.length === 0">
                    Es sind keine Standorte zur Auswahl vorhanden
                  </b-dropdown-text>
                </b-dropdown>
              </template>
            </b-form-tags>
          </b-col>
        </b-form-row>
      </b-form-group>
    </TransitionExpand>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import TransitionExpand from "@/components/utility/TransitionExpand"

export default {
  name: "FavoriteSiteSettings",
  props: ['userSettings'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false,
      siteSelect: {
        search: '',
        value: [],
        options: []
      }
    }
  },
  methods: {
    emitUserSetting() {
      let data = []
      this.siteSelect.value.forEach(element => {
        data.push(this.siteSelect.options.filter(opt => opt.displayname === element)[0].id)
      })
      this.userSettings.site_favorites = data
      this.$emit('settings-changed', this.userSettings)
    },
    onOptionClick({option, addTag}) {
      addTag(option.displayname)
      this.siteSelect.search = ''
    }
  },
  computed: {
    ...mapGetters(['sites']),
    criteria() {
      return this.siteSelect.search.trim().toLowerCase()
    },
    searchDesc() {
      if(this.criteria && this.availableOptions.length === 0) {
        return 'Es konnten keine Standorte nach dem Suchkriterium gefunden werden'
      }
      return ''
    },
    availableOptions() {
      const criteria = this.criteria
      let options = this.siteSelect.options.filter(opt => this.siteSelect.value.indexOf(opt.displayname) === -1)
      if(criteria) {
        return options.filter(opt => String(opt.displayname.toLowerCase()).includes(criteria))
      }
      return options
    }
  },
  mounted() {
    this.siteSelect.options = this.sites
    if(this.userSettings.site_favorites.length > 0) {
      this.userSettings.site_favorites.forEach(element => {
        let data = this.siteSelect.options.filter(opt => opt.id === element)
        this.siteSelect.value.push(data[0].displayname)
      })
    }
  }
}
</script>

<style lang="scss">
.dropdown-menu {
  max-height: 15rem;
  overflow-y: auto;
}
</style>
