<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8">
          <b-jumbotron class="jumbotron-background py-4 mt-3" header="Benutzerprofil" header-level="5">
            <hr class="line-white">
            <UserInformation/>
            <hr class="line-white">
            <UserVPN :userVPN="userVPN" @refresh="getUserVPN"/>
            <hr class="line-white">
            <b-button variant="outline-danger" @click="revoke">Alle aktiven Anmeldungen beenden</b-button>
          </b-jumbotron>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";
import ScrollToTop from "@/components/utility/ScrollToTop";
import UserVPN from "@/components/userAccount/userProfile/UserVPN";
import UserInformation from "@/components/userAccount/userProfile/UserInformation";

export default {
  name: "UserProfile",
  components: {
    UserVPN,
    UserInformation,
    ScrollToTop
  },
  data() {
    return {
      userVPN: null
    }
  },
  methods: {
    ...mapActions(['signOut']),
    ...mapActions(['revokeTokens']),
    ...mapActions(['setLoadingState']),
    async revoke() {
      await this.revokeTokens()
      await this.signOut()
      await this.$router.replace('/login')
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getUserVPN()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async getUserVPN() {
      await axios.get(process.env.VUE_APP_API_USERS_VPN_GET)
          .then((response) => {
            this.userVPN = response.data
      })
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Dashboard';

.line-white {
  border-color: white;
}
</style>
