<template>
  <b-card body-class="p-0" header-tag="header" header-class="card-row">
    <template #header>
      <b-container fluid class="px-2 pt-1 pb-2">
        <b-row>
          <b-col cols="auto" class="p-0">
            <CustomerOnline :customer="customer"/>
            <CustomerType :customer="customer"/>
          </b-col>
          <b-col cols="1">
            <Clipboard :value="customer.kdnr" title="Kundennummer kopieren"/>
            <span v-if="customer.kdnr">
              <a target="_blank" :href="'https://webams.alfanetz.de/teilnehmer/Teilnehmer/' + kdnr">
                <b-icon-link45deg :id="customer.kdnr"/>
                <b-tooltip :target="customer.kdnr">Link zum AMS</b-tooltip>
              </a>
            </span>
          </b-col>
          <b-col cols="2">
            <Clipboard :value="customer.zuname + ', ' + customer.vorname" title="Name kopieren"/>
          </b-col>
          <b-col cols="3">
            <Clipboard :value="customer.intklr + ' / ' + customer.kurs" title="Klassenraumnummer und Kurs kopieren"/>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0">
            <EmployeeDisplay :customer="customer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <CustomerDetails :customer="customer" @refresh="refresh"/>
  </b-card>
</template>

<script>
import Clipboard from "@/components/utility/Clipboard.vue";
import CustomerType from "@/components/lists/customerList/customerRow/CustomerType";
import CustomerDetails from "@/components/details/customerDetails/CustomerDetails";
import CustomerOnline from "@/components/lists/customerList/customerRow/CustomerOnline";
import EmployeeDisplay from "@/components/lists/customerList/customerRow/EmployeeDisplay";

export default {
  name: "CustomerRowSingle",
  props: ['customer'],
  components: {
    CustomerType,
    CustomerOnline,
    CustomerDetails,
    Clipboard,
    EmployeeDisplay
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    kdnr() {
      let reg = /(\d*)[A-Z]{2}/
      if(this.customer.kdnr[0] === 'D' || this.customer.kdnr[0] === 'T') {
        return null
      }
      return this.customer.kdnr.match(reg)[1]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/Color.scss";

.dark .card-row:hover {
  background-color: $grey-700 !important;
}

.light .card-row:hover {
  background-color: $grey-100 !important;
}
</style>
