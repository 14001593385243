<template>
  <div>
    <h5 class="text-bold clickable" @click.self="expand = !expand">Thema und Farbeinstellungen
      <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      <b-icon-info-circle-fill class="ml-2" id="tt-info-theme"/>
      <b-tooltip target="tt-info-theme" triggers="hover">Diese Features sind noch nicht verfügbar</b-tooltip>
    </h5>
    <TransitionExpand>
      <b-form-group v-if="expand">
        <b-form-checkbox v-model="userSettings.frontend_theme_light" switch @change="emitUserSetting">
          Helles Farbthema
        </b-form-checkbox>
        <b-form-row class="mt-2">
          <b-col cols="3">
            <label>Farbsinnstörung:</label>
            <b-form-select v-model="accessibilitySelect.selected" :options="accessibilitySelect.options" size="sm"
                           @input="emitUserSetting"/>
          </b-col>
        </b-form-row>
      </b-form-group>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/utility/TransitionExpand"

export default {
  name: "ThemeSettings",
  props: ['userSettings'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false,
      accessibilitySelect: {
        selected: 'null',
        options: [
          { value: 'null', text: 'Keine Auswahl' },
          { value: 'red-green', text: 'Grünblindheit (Deuteranopie)'},
        ]
      }
    }
  },
  watch: {
    'userSettings.frontend_color_accessibility'() {
      if(this.userSettings.frontend_color_accessibility === "") {
        this.accessibilitySelect.selected = 'null'
        this.emitUserSetting()
      }
      else {
        this.accessibilitySelect.selected = this.userSettings.frontend_color_accessibility
        this.emitUserSetting()
      }
    }
  },
  methods: {
    emitUserSetting() {
      this.userSettings.frontend_color_accessibility = this.accessibilitySelect.selected
      this.$emit('settings-changed', this.userSettings)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
