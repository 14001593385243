<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="auto">
                <div class="text-white">Einzelansicht von {{ customer.zuname}}, {{ customer.vorname }} ({{ customer.kdnr }})</div>
              </b-col>
              <b-col cols="auto" class="ml-auto">
                <small class="ml-2">Datenabruf um: {{ lastDataFetched }}</small>
              </b-col>
            </b-row>
          </SubHeader>
          <CustomerRowSingle v-if="this.customer" :customer="customer" class="mt-3" @refresh="refresh"/>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import CustomerRowSingle from "@/components/singleDisplay/CustomerRowSingle";

export default {
  name: "Customer",
  components: {
    SubHeader,
    CustomerRowSingle
  },
  data() {
    return {
      customer: null,
      lastDataFetched: '-'
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getCustomer() {
      await getRequest('customer/' + this.kdnr, null, this)
          .then((response) => {
            this.customer = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getCustomer()
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    }
  },
  computed: {
    kdnr() {
      if(this.$route.params.kdnr) {
        return this.$route.params.kdnr
      }
      return null
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
