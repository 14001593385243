<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8">
          <SubHeader class="mb-2">
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm"
                              placeholder="Suche nach Überschrift oder Kategorie..."/>
              </b-col>
            </b-row>
          </SubHeader>
          <NewsSingle v-for="news in newsFiltered" :key="news.id" :news="news" class="mb-2"/>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import NewsSingle from "@/components/news/NewsSingle";
import ScrollToTop from "@/components/utility/ScrollToTop";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "News",
  components: {
    SubHeader,
    NewsSingle,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      news: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getNews() {
      await getRequest('news/all', null, this)
          .then((response) => {
            this.news = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getNews()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    }
  },
  computed: {
    newsFiltered() {
      return _.filter(this.news, (entry) => {
        return entry.headline.toLowerCase().includes(this.search.toLowerCase()) ||
            entry.news_category.displayname.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
