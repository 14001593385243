<template>
  <b-card-body class="details-expand text-white pt-3 px-3 pb-2">
    <b-container fluid class="p-0">
      <TileComputer title="Computer" :customer="customer" @refresh="refresh"/>
    </b-container>
  </b-card-body>
</template>

<script>
import TileComputer from "@/components/details/customerDetails/tiles/TileComputer";

export default {
  name: "CustomerDetails",
  props: ['customer'],
  components: {
    TileComputer
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Details.scss';
</style>
