<template>
  <b-card body-class="p-0" header-tag="header" header-class="card-row">
    <template #header>
      <b-container fluid class="px-2 pt-1 pb-2">
        <b-row>
          <b-col cols="auto" class="p-0">
            <ComputerOnline :computer="computer"/>
            <UserConnected :computer="computer"/>
            <WinUser :computer="computer"/>
            <CustomerAssigned :computer="computer"/>
          </b-col>
          <b-col cols="auto">
            <HostnameControl :computer="computer" @close-details="() => { if(this.expand) { getDetails() }}"
                             @refresh="refresh"/>
          </b-col>
          <b-col cols="1" class="px-0">
            <Clipboard :value="computer.ipv4_address" title="IPv4-Adresse kopieren"/>
          </b-col>
          <b-col cols="1">
            <HardwareInformation :computer="computer"/>
          </b-col>
          <b-col cols="1">
            <ImageInformation :computer="computer" @refresh="refresh"/>
          </b-col>
          <b-col cols="6">
            <TasksDisplay :computer="computer" @refresh="refresh"/>
          </b-col>
          <b-col cols="auto" class="p-0">
            <ShutdownControl :computer="computer" @refresh="refresh"/>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0">
            <ComputerWarnings :computer="computer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <ComputerDetails :computer="computer" :singleView="true" @refresh="refresh"/>
  </b-card>
</template>

<script>
import Clipboard from "@/components/utility/Clipboard.vue";
import WinUser from "@/components/lists/computerList/computerRow/WinUser";
import ComputerDetails from "@/components/details/computerDetails/ComputerDetails";
import TasksDisplay from "@/components/lists/computerList/computerRow/TasksDisplay";
import UserConnected from "@/components/lists/computerList/computerRow/UserConnected";
import ComputerOnline from "@/components/lists/computerList/computerRow/ComputerOnline";
import ShutdownControl from "@/components/lists/computerList/computerRow/ShutdownControl";
import HostnameControl from "@/components/lists/computerList/computerRow/HostnameControl";
import ComputerWarnings from "@/components/lists/computerList/computerRow/ComputerWarnings";
import ImageInformation from "@/components/lists/computerList/computerRow/ImageInformation";
import HardwareInformation from "@/components/lists/computerList/computerRow/HardwareInformation";
import CustomerAssigned from "@/components/lists/computerList/computerRow/CustomerAssigned";

export default {
  name: "ComputerRowSingle",
  props: ['computer'],
  components: {
    Clipboard,
    ComputerDetails,
    ComputerWarnings,
    ShutdownControl,
    TasksDisplay,
    ImageInformation,
    HardwareInformation,
    HostnameControl,
    UserConnected,
    ComputerOnline,
    WinUser,
    CustomerAssigned,
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/Color.scss";

.dark .card-row:hover {
  background-color: $grey-700 !important;
}

.light .card-row:hover {
  background-color: $grey-100 !important;
}
</style>
