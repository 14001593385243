<template>
  <div class="badge-fix">
    <b-badge variant="primary">Admin:
      <span v-if="customer.user_admin">{{ customer.user_admin.username }}</span>
      <span v-else>-</span>
    </b-badge>
    <b-badge variant="primary" class="ml-1">Sek:
      <span v-if="customer.user_sek">{{ customer.user_sek.username }}</span>
      <span v-else>-</span>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: "EmployeeDisplay",
  props: ['customer']
}
</script>

<style lang="scss" scoped>
</style>
